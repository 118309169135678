import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e8773a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__left" }
const _hoisted_3 = { class: "header__right" }
const _hoisted_4 = { href: "/dashboard/settings" }
const _hoisted_5 = { class: "header__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Burger = _resolveComponent("Burger")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LocalChanger = _resolveComponent("LocalChanger")!
  const _component_Close = _resolveComponent("Close")!
  const _component_NavMenu = _resolveComponent("NavMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Burger, {
        onClick: _ctx.openMobMenu,
        class: "header__burger"
      }, null, 8, ["onClick"]),
      _createVNode(_component_router_link, { to: "/dashboard/main" }, {
        default: _withCtx(() => [
          _createVNode(_component_Logo, { class: "header__logo" })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_LocalChanger),
      _createElementVNode("a", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(this.firstName) + " " + _toDisplayString(this.lastName), 1)
      ]),
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout())),
        class: "header__logout"
      }, _toDisplayString(_ctx.$t('message.log_out')), 1)
    ]),
    _withDirectives(_createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeMobMenu && _ctx.closeMobMenu(...args)), ["self"])),
      class: "header__menu"
    }, [
      _createVNode(_component_Close, {
        class: "header__menu-close",
        onClick: _withModifiers(_ctx.closeMobMenu, ["self"])
      }, null, 8, ["onClick"]),
      _createVNode(_component_NavMenu)
    ], 512), [
      [_vShow, _ctx.mobileMenu]
    ])
  ]))
}