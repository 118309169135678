
import { defineComponent } from 'vue'
import Logo from '@/assets/svg/logo.svg?inline'
import Burger from '@/assets/svg/burger.svg?inline'
import Close from '@/assets/svg/close-white.svg?inline'
import NavMenu from '@/components/molecules/NavMenu.vue'
import LocalChanger from '@/components/molecules/LocalChanger.vue'

export default defineComponent({
  name: 'Header',
  data () {
    return {
      mobileMenu: false,
      user: {},
      firstName: '',
      lastName: ''
    }
  },
  components: {
    Logo,
    Burger,
    NavMenu,
    LocalChanger,
    Close
  },
  mounted () {
    this.getProfile()
  },
  methods: {
    getProfile () {
      this.$store.dispatch('profile/getProfile').then(
        (res) => {
          this.user = res
          this.firstName = this.user.first_name
          this.lastName = this.user.last_name
        },
        (error) => {
          console.log(error)
        }
      )
    },
    logout () {
      this.$store.dispatch('auth/logout').then(
        (res) => {
          this.$router.push('/')
        },
        (error) => {
          console.log(error)
        }
      )
    },
    openMobMenu () {
      this.mobileMenu = true
    },
    closeMobMenu () {
      this.mobileMenu = false
    }
  }
})
