import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-484bba7b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-menu__container" }
const _hoisted_2 = { class: "nav-menu" }
const _hoisted_3 = { class: "nav-menu__bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LocalChanger = _resolveComponent("LocalChanger")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Logo, { class: "nav-menu__logo" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "nav-menu__link",
        to: "/dashboard/main"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.nav.dashboard')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "nav-menu__link",
        to: "/dashboard/property"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.nav.property')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "nav-menu__link",
        to: "/dashboard/integrations"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.nav.integrations')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "nav-menu__link",
        to: "/dashboard/settings"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.nav.settings')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout())),
        class: "nav-menu__logout"
      }, _toDisplayString(_ctx.$t('message.log_out')), 1),
      _createVNode(_component_LocalChanger, { preKey: 'nav' })
    ])
  ]))
}