<template>
  <div class="main">
    <Header />
    <div class="main__container">
      <div class="main__nav">
        <NavMenu />
      </div>
      <slot />
    </div>
  </div>

</template>

<script>
import { defineComponent } from 'vue'
import NavMenu from '@/components/molecules/NavMenu.vue'
import Header from '@/components/sections/Header.vue'

export default defineComponent({
  name: 'main-template',
  components: {
    NavMenu,
    Header
  }
})
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__container {
    display: flex;
  }

  @media (max-width: $break-lg) {
    .main {
      &__nav {
        display: none;
      }
    }
  }
}
</style>
